import React, { useEffect, useState } from 'react';
import { Signer, ethers } from 'ethers';
import './App.css';
import App from './App';
import Modal from './components/Modal';
import Main from './components/Main';
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';


const API_KEY = 'RFm7T7AN.U1EC7oSjfqPW4l3paTzvDPtJLFPH1G1D';
const SCORER_ID = 1362;

const SUBMIT_PASSPORT_URI = 'https://api.scorer.gitcoin.co/registry/submit-passport';
const SIGNING_MESSAGE_URI = 'https://api.scorer.gitcoin.co/registry/signing-message';
const THRESHOLD_NUMBER = 20;

const headers = API_KEY
  ? {
      'Content-Type': 'application/json',
      'X-API-Key': API_KEY,
    }
  : undefined;

const Index = () => {
  const [address, setAddress] = useState('');
  const [connected, setConnected] = useState(false);
  const [score, setScore] = useState('');
  const [noScoreMessage, setNoScoreMessage] = useState('');

  useEffect(() => {
    checkConnection();
    async function checkConnection() {
            try {
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const accounts = await provider.listAccounts();
              if (accounts && accounts[0]) {
                setConnected(true);
                setAddress(accounts[0]);
                checkPassport(accounts[0]);
              }
            } catch (err) {
              console.log('not connected...');
            }
          }
  }, []);


    async function getSigningMessage() {
    try {
      const response = await fetch(SIGNING_MESSAGE_URI, { headers });
      const json = await response.json();
      return json;
    } catch (err) {
      console.log('error: ', err);
    }
  }

  function submitPassport() {
    setNoScoreMessage('');
    getSigningMessage().then(({ message, nonce }) => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      return Promise.resolve(provider.getSigner()).then((signer) => {
        return signer.signMessage(message).then((signature) => {
          console.log(signature)
          const requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify({
              address,
              scorer_id: SCORER_ID,
              signature,
              nonce,
            }),
          };
          return fetch(SUBMIT_PASSPORT_URI, requestOptions);
        });
      });
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log('data:', data);
    }).catch((error) => {
      console.log('error:', error);
    });
  }
  
  
function connect() {
  return window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
    setAddress(accounts[0]);
    setConnected(true);
    return checkPassport(accounts[0]);
  }).catch((err) => {
    console.log('error connecting...');
  });
}


    async function checkPassport(currentAddress = address) {
    setScore('');
    setNoScoreMessage('');

    console.log(currentAddress)
    const GET_PASSPORT_SCORE_URI = `https://api.scorer.gitcoin.co/registry/score/${SCORER_ID}/${currentAddress}`;
    try {
      const response = await fetch(GET_PASSPORT_SCORE_URI, { headers });
      console.log(response)
      const passportData = await response.json();
      if (passportData.score) {
        const roundedScore = Math.round(passportData.score * 100) / 100;
        setScore(roundedScore.toString());
      } else {
        console.log('No score available, please add stamps to your passport and then resubmit.');
        setNoScoreMessage('No score available, please submit your passport after you have added some stamps.');
      }
    } catch (err) {
      console.log('error: ', err);
    }
    
  }

function loginHandler() {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  provider.listAccounts().then((accounts) => {
    if (accounts && accounts[0]) {
      setAddress(accounts[0]);
      setConnected(true);
    } else {
      return connect();
    }
  }).then(() => {
    return submitPassport();
  }).then(() => {
    return checkPassport();
  }).then(() => {
    return getSigningMessage();
  }).catch((error) => {
    console.log('error:', error);
  });
}




  return (
    <div>
      <App />
      <Main loginHandler={loginHandler} />
    </div>
  );

};







createRoot(document.getElementById('root')).render(<Index />);
reportWebVitals();
