import React from 'react';
import './App.css';
import './index.js';
import Main from './components/Main';





const App = () => {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
