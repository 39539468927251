import React, { useState } from 'react';
import Modal from './Modal';

const Main = ({ loginHandler }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {/* Video Source */}
      {/* https://www.pexels.com/video/aerial-view-of-beautiful-resort-2169880/ */}
      <section className="showcase">
        <video src="grb.mp4" muted loop autoPlay />

        <div className="text">
          <h2>DeCartography</h2>

          <p className="container glass">
            DeCartography is a computational oracle that aims to provide multidimensional ties such as Social Diversity
            and Social Distance between wallet addresses based on their on-chain activity in the Ethereum network. The
            goal is to provide a near-objective social graph using the notion of common knowledge "wisdom of the crowd"
            by iterating subjective human evaluations of each address against a community-defined cluster. Our current
            demo includes data from the latest gitcoin round (Round 15).
          </p>

          <a href="#" className="openModalBtn" onClick={() => setModalOpen(true)}>
            Try DeCartography
          </a>
          {modalOpen && <Modal closeModal={closeModal} loginHandler={loginHandler} />}
        </div>

        <ul className="social">
          <li>
            <a href="#">
              <img src="github.png" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="twitter.png" />
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Main;
