import React, { useState } from 'react';
import './Modal.css';

const Modal = ({ closeModal, loginHandler }) => {
  const [loading, setLoading] = useState(false);

  const handleloginHandlerClick = () => {
    setLoading(true);
    loginHandler();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <button className="modal-close" onClick={closeModal}>
            &times;
          </button>

          <button
            className={`openModalBtn ${loading ? 'loadingButton' : ''}`}
            onClick={handleloginHandlerClick}
          >
            {loading ? (
              <span id="loading">
                
                <span>&bull;</span>

                <span>&bull;</span>

                <span>&bull;</span>

              </span>
            ) : (
              <>
                <img src="gitlogo.png" className="button-image" alt="Button Image" />
                Login with Gitcoin Passport
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
